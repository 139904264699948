import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyB-bXuw5bln2IvdyF1jY4RsQEDj7BBSKmg",
  authDomain: "stranger-club.firebaseapp.com",
  projectId: "stranger-club",
  storageBucket: "stranger-club.appspot.com",
  messagingSenderId: "588500857405",
  appId: "1:588500857405:web:9f8adcd3ecfce615773054",
  measurementId: "G-T0YRGY2HEH",
  databaseURL: "https://stranger-club-default-rtdb.asia-southeast1.firebasedatabase.app"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase;

var firepadRef = firebase.database().ref();

export default firepadRef;
