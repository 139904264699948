import firepadRef from "./firebase";
import { hasValue } from "../utils";
import { store } from "../index";

export const updatePreference = (userId, preference, roomId) => {
  if (hasValue(roomId)) {
    let firebaseRef = firepadRef.child(roomId);
    if (hasValue(firebaseRef)) {
      const participantRef = firebaseRef.child("participants");
      const currentParticipantRef = participantRef
        .child(userId)
        .child("preferences");
      setTimeout(() => {
        currentParticipantRef.update(preference);
      });
    }
  }
};

export const createOffer = async (peerConnection, receiverId, createdID, roomId) => {
  if (hasValue(roomId)) {
    let firebaseRef = firepadRef.child(roomId);
    if (hasValue(firebaseRef)) {
      const participantRef = firebaseRef.child("participants");
      const currentParticipantRef = participantRef.child(receiverId);
      peerConnection.onicecandidate = (event) => {
        event.candidate &&
          currentParticipantRef
            .child("offerCandidates")
            .push({ ...event.candidate.toJSON(), userId: createdID });
      };

      const offerDescription = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offerDescription);

      const offer = {
        sdp: offerDescription.sdp,
        type: offerDescription.type,
        userId: createdID,
      };

      await currentParticipantRef.child("offers").push().set({ offer });
    }
  }
};

export const initializeListensers = async (userId, roomId) => {
  if (hasValue(roomId)) {
    let firebaseRef = firepadRef.child(roomId);
    if (hasValue(firebaseRef)) {
      const participantRef = firebaseRef.child("participants");
      const currentUserRef = participantRef.child(userId);
      currentUserRef.child("offers").on("child_added", async (snapshot) => {
        const data = snapshot.val();
        if (data?.offer) {
          if (hasValue(store?.getState()?.participants ?? "")) {
            const pc =
              store.getState().participants[data.offer.userId].peerConnection;
            await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
            await createAnswer(data.offer.userId, userId, roomId);
          }
        }
      });

      currentUserRef.child("offerCandidates").on("child_added", (snapshot) => {
        const data = snapshot.val();
        if (data.userId) {
          if (hasValue(store?.getState()?.participants ?? "")) {
            const pc = store.getState().participants[data.userId].peerConnection;
            console.log(pc.addIceCandidate, 'pc.addIceCandidate')
            pc.addIceCandidate(new RTCIceCandidate(data));
          }
        }
      });

      currentUserRef.child("answers").on("child_added", (snapshot) => {
        const data = snapshot.val();
        if (data?.answer) {
          if (hasValue(store?.getState()?.participants ?? "")) {
            const pc =
              store.getState().participants[data.answer.userId].peerConnection;
            const answerDescription = new RTCSessionDescription(data.answer);
            pc.setRemoteDescription(answerDescription);
          }
        }
      });

      currentUserRef.child("answerCandidates").on("child_added", (snapshot) => {
        const data = snapshot.val();
        if (data.userId) {
          if (hasValue(store?.getState()?.participants ?? "")) {
            const pc = store.getState().participants[data.userId].peerConnection;
            pc.addIceCandidate(new RTCIceCandidate(data));
          }
        }
      });
    }
  }
};

const createAnswer = async (otherUserId, userId, roomId) => {
  if (hasValue(roomId)) {
    let firebaseRef = firepadRef.child(roomId);
    if (hasValue(firebaseRef)) {
      if (hasValue(store?.getState()?.participants ?? "")) {
        const participantRef = firebaseRef.child("participants");
        const pc = store.getState().participants[otherUserId].peerConnection;
        const participantRef1 = participantRef.child(otherUserId);
        pc.onicecandidate = (event) => {
          event.candidate &&
            participantRef1
              .child("answerCandidates")
              .push({ ...event.candidate.toJSON(), userId: userId });
        };

        const answerDescription = await pc.createAnswer();
        await pc.setLocalDescription(answerDescription);

        const answer = {
          type: answerDescription.type,
          sdp: answerDescription.sdp,
          userId: userId,
        };

        await participantRef1.child("answers").push().set({ answer });
      }
    }
  }
};
