import React, { useState } from "react";
import {  BrowserRouter, Route, Switch  } from "react-router-dom";

const IndexPage = React.lazy(() => import("../views/IndexPage"));
const ConnnetCall = React.lazy(() => import("../views/ConnnetCall"));

export default function AppRoutes() {
  return (
    <BrowserRouter forceRefresh >
      <React.Suspense fallback="Loading">
        <Switch>
          <Route path={"/home"} render={(props) => <ConnnetCall {...props}/>}  />
          <Route path={""} component={IndexPage} />
        </Switch>
        </React.Suspense>
    </BrowserRouter>
  );
}
