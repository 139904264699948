import moment from 'moment';

export const hasValue = (data) => {
  return (data !== undefined) && (data !== null) && (data !== "");
}

export const dateTime = (data, prev_format, show_format) => {
  let res = moment(data ? data : new Date(), prev_format).format(show_format ? show_format : 'YYYY-MM-DD HH:mm:ss')
  if (!hasValue(res)) { res = "" }
  if (res === 'Invalid date') { res = "" }
  return res;
}

export const uniqueId = function () {
  const uniqueId = (prefix = 'id-') => prefix + Math.random().toString(16).slice(-4)
  return uniqueId()
}